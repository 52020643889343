/* eslint-disable */
/**
 * 登录页相关GA/SA/sensors埋点
 */
import Vue from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { parseQueryString } from '@shein/common-function'
daEventCenter.addSubscriber({ modulecode: '2-8' })
import { abtservice } from 'public/src/services/abt/index.js'
daEventCenter.addSubscriber({ modulecode: '2-16' })
import { isShowEmailSubscribe } from 'public/src/pages/login/util'

function createInitEvents () {
  if (typeof window == 'undefined') return {
    sensorsSend: () => {}
  }
  if(!window._GB_LoginEventCenter_){
    window._GB_LoginEventCenter_ = new Vue()
  }
  // 对外提供的调用方法
  Vue.prototype.$evt = window._GB_LoginEventCenter_
  const evtCenter = window._GB_LoginEventCenter_
  const { langPath, lang } = gbCommonInfo
  // 是否是用户中台一期
  const isTestUserMedium = true

  // 登录统计saPageInfo信息
  // const loginSaPageInfo = typeof window.SaPageInfo !== 'undefined' ? window.SaPageInfo : { start_time: new Date().getTime() }
  const getLoginSaPageInfo = () => (window.getSaPageInfo || window.SaPageInfo || { start_time: new Date().getTime() })
  let loginPageTriggerOrigin = '' // 设置触发登录弹窗显示的来源
  let loginPageTriggerBiExtra = '' // 设置触发登录弹窗显示的Bi来源的额外信息

  // key值为小写，因为在入口已经强制转换为小写
  const sences = {
    livechat: ['live_chat', 'livechat'],
    login: ['other', 'login'],
    checkout: ['checkout', 'checkout'],
    other: ['other', 'other'],
    activity: ['activity', '专题'],
    wishlist: ['wishlist', 'wishlist'],
    freetrial: ['freetrial', 'freetrial'],
  }

  // 判断是否在登录页面，否则作为login弹窗
  const isLoginPageAsCompnent = !/\/user\/login$/.test(location.pathname) && !/\/auth\/login$/.test(location.pathname)

  /**
   * 格式化登录组件or页面的来源
   * @param [string] scene 特殊场景，需要自定义scence,不取全局，比如：googleonetapsign
   * @returns 
   */
  const loginPageFrom = function(scene = '') {
    if (scene) return scene
    if(isLoginPageAsCompnent) {
      const { bi = '' } = SHEIN_LOGIN.instanceActions.getOpts() || {}

      if (bi) { // 如果已设置触发场景来源
        return bi
      }
    }
    if (!!loginPageTriggerOrigin) { // 如果有设置了登录弹窗的触发来源，后面的代码为了向后兼容
      return formatBiScenes()
    }

    const pathname = location.pathname
    const params = parseQueryString(location.search)
    let redirection = params.redirection || ''
    let direction = params.direction || ''

    direction = direction.split('?')[0] // 去掉参数
    redirection = redirection.split('?')[0] // 去掉参数

    let matchUrl = /\/user\/login$/.test(pathname) || /\/auth\/login$/.test(pathname) ? redirection : pathname

    if (/user\/prime$/.test(matchUrl)) { // 会员club
      return 'club'
    }

    if (/student-discount\.html$/.test(matchUrl)) { // 学生折扣
      return 'student_discount'
    }
    if (/\/.+-p-(\d+)(-cat-(\d+))?\.html$/.test(matchUrl) || /cart$/.test(matchUrl)) { // 详情页
      return 'wishlist'
    }

    if (matchUrl.indexOf('campaign/') > -1) { // 活动页
      return 'activity'
    }

    if (matchUrl.indexOf('creatorcenter') > -1) { 
      return 'kol'
    }

    if (matchUrl.indexOf('user/wishlist') > -1) { // 收藏
      return 'wishlist'
    }

    const fromUserPattren = new RegExp(`^(${langPath})?/user/.+`)
    if ( direction === 'nav' || fromUserPattren.test(matchUrl)) { // 登录页并且来自user子页
      return 'me'
    }
    return 'other'
  }

  function formatBiScenes() {
    const from = loginPageTriggerOrigin
    const extra = loginPageTriggerBiExtra
    const sc = (sences[from] && sences[from][0]) || ''
    if (extra) { // 优先使用biExtra场景
      return extra
    }
    return sc
  }

  /**
   * 格式化自有埋点相关参数
   * @param params
   */
  function formatSaActParams(params = {}) {
    let base = {
      login_from: loginPageFrom()
    }
    let abt = {}
    // 购物车页面会有abt参数，需要传相关参数
    if (typeof window.__loginAbtParams__ !== 'undefined') { // 购物车页面
      const abtest = abtservice.getUserAbtResultForAnalysis({ posKeys: window.__loginAbtParams__.poskey }).sa

      abt = {
        trigger: 'page_cart',
        abtest,
      }
    }

    base = { ...base, ...params, ...abt }

    return !!Object.keys(base).length ? base : null
  }

  /**
   * 获取自有埋点的真实activity_name
   */
  function getBiRealName(name) {

    if (/^popup/.test(name)) return name

    if (isLoginPageAsCompnent) {
      return `popup_login_${name}`
    }

    return name
  }

  /**
   * 自有埋点发送(send)统计方法
   * @param name activity_name
   * @param param activity_param 活动参数
   * @param type 类型（expose/click) 默认为false: click类型
   * @param beacon 发送类型，默认为false
   * @param customInfo 定制SaPageInfo, 如果需要覆盖或者添加SaPageInfo
   */
  function saSend(name, param = {}, type = false, beacon = false, customInfo = null) {
    let info = { ...getLoginSaPageInfo() }
    if (/result$/.test(name)) {
      beacon = false
    }

    if (!!customInfo && typeof customInfo === 'object') { // 定制类SaPageInfo
      info = { ...info, ...customInfo }
    }

    const realName = getBiRealName(name) // 区分组件name和页面name

    info.activity_name = type ? `expose_${realName}` : `click_${realName}`

    const actParams = formatSaActParams(param)

    if (actParams) info.activity_param = actParams

    // 如果埋点信息里面是成功标志, 删除失败原因字段
    if (info.activity_param && info.activity_param.status === 'success') {
      info.activity_param.failure_reason && (delete info.activity_param.failure_reason)
    }
    sa('send', info, beacon ? { beacon: 1, image: 1} : '')
  }

  function saSendNew(name = '', param = {}, type, beacon) {
    const data = {
      login_from: loginPageFrom(),
      ...param,
    }

    window.sa?.('send', { activity_name: name, activity_param: data }, beacon ? { beacon: 1, image: 1} : '')
  }

  /**
   * 一般ga埋点发送(send)统计方法
   * @param action
   * @param label
   * @param value
   * @param category
   * @param beacon
   */
  function gaSend({ action, label = '', value, category = '登录注册页', beacon = false }) {
    const params = formatGaParams({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: `${label}`,
    })
    if (value !== undefined) params.eventValue = value
    if (beacon) params.transport = 'beacon'
  }

  /**
   * 格式化GA场景字段
   */
  function formatGaScenes() {
    const { loginType, redirection ='', act_name = '' } = parseQueryString(location.search)
    const { ga = '' } = SHEIN_LOGIN.instanceActions.getOpts() || {}

    if (ga) { // 如果已设置触发场景来源
      return ga
    }

    if (/\/user\/login$/.test(location.pathname)) { // 如果是在登录页
      if (loginType === 'special-coupon') return '专题领券'
      if (loginType === 'special-luckyDraw') return '专题抽奖'
      // hack....
      const matchUrl = redirection.split('?')[0]
      if (/new-user-coupon.html$/.test(matchUrl)) return 'other'
      if (/student.html$/.test(matchUrl)) return '活动页'
      if (act_name === 'student_register_201903') return '线下活动页'
      return 'other'
    }

    return 'other'
  }

  const gaToSensors = function (scenes) {
    const gaToSensors = {
      login: '正常',
      checkout: '购买中',
      coupons: '券包',
      wishlist: '收藏商品',
      '专题抽奖': '专题抽奖',
      '专题领券': '专题领券',
      livechat: '联系客服',
      account: '查看账户',
      freetrial: '免费试用',
      shareandearn: '全民营销拉新',
      googleonetapsign: 'googleonetapsign',
      other: '其他'
    }
    const { sensors = '' } = SHEIN_LOGIN.instanceActions.getOpts() || {}
    const sensorsScenes = sensors || gaToSensors[scenes] || '其他'
    return sensorsScenes
  }

  function sensorsSend(daId, extraData = {}) {
    const scenes = formatGaScenes()
    const sensorsScenes = gaToSensors(scenes)
    extraData.scenes = sensorsScenes
    extraData.sensorsScenes = sensorsScenes
    extraData.gaScenes = scenes
    daEventCenter.triggerNotice({
      daId,
      extraData
    })
  }

  /**
   * 格式化Ga埋点的params，区分不同page页面的action或者label的区别
   * @param params
   */
  function formatGaParams(params = {}) {
    const scenes = formatGaScenes()
    let { eventLabel } = params

    eventLabel = eventLabel ? `${scenes}_${eventLabel}` : scenes

    params.eventLabel = eventLabel
    return params
  }

  // 设置当前埋点数据场景给中转页用
  function setScenesToSession () {
    const ga = formatGaScenes()
    const bi = formatBiScenes()
    const sen = gaToSensors(ga)
    window && window.sessionStorage && window.sessionStorage.setItem && window.sessionStorage.setItem('loginScenes', JSON.stringify({ ga, bi, sen }))
  }

  function GB_ga_transformPrice (val, isNoCache) {
    if (isNoCache) {
      return (val / GB_ga_currentRate).toFixed(2)
    } else {
      if (typeof (GB_ga_currentRate) !== 'undefined' && GB_ga_currentRate) {
        return (val / GB_ga_currentRate).toFixed(2)
      } else {
        return val
      }
    }
  }

  // 添加产品到ga跟踪埋点
  evtCenter.$on('request-cart-number-success', function(data) { //
    try{
      // if (data.code == 0) {
      if (data.cartlists && data.cartlists.info) {
        data.cartlists.info.carts.forEach(function(item) {
          const gapItem = {
            id: item.product.productRelationID||item.product.goods_sn,
            name: item.product.goods_sn,//item.product.goods_name,
            category: item.product.cat_id,
            variant: item.attr.attr_value,
            price: GB_ga_transformPrice(item.product.salePrice.amount),
            quantity: item.quantity,
          };
          if(item.attr.attr_value) {
            gapItem.variant = item.attr.attr_value
          }
        })
      }
    } catch(e) {
      console.log(e)
    }
  })

  // 点击登录tab切换
  evtCenter.$on('click-tab', function({ label, privacyType }) {
    gaSend({ action: 'ClickTab', label })
  })

  // input框聚焦事件
  evtCenter.$on('login-page-input-focus', function(data) {
    const { target } = data
    if (target === 'login-email') {
      gaSend({ action: 'EditEmail', label: '登录页'})
      sensorsSend('2-8-6', { tab_content: 'Login' })
      return
    }
    if (target === 'login-password') {
      gaSend({ action: 'EditPassword', label: '登录页'})
      sensorsSend('2-8-7', { tab_content: 'Login' })
      return
    }
    if (target === 'register-email') {
      gaSend({ action: 'EditEmail', label: '注册页'})
      sensorsSend('2-8-6', { tab_content: 'Register' })
      return
    }
    if (target === 'register-password') {
      gaSend({ action: 'EditPassword', label: '注册页'})
      sensorsSend('2-8-7', { tab_content: 'Register' })
      // return
    }
  })

  // 登录模块login按钮点击
  evtCenter.$on('click-login-btn', function(value) {
    saSend('signin', )
  })

  //泄露名单弹窗点击修改密码
  evtCenter.$on('click-reveal-btn', function(value) {
    sa('send', {
      page_id: 15, 
      page_name: 'page_login',
      activity_name: value ? 'click_change' : 'click_skip',
    })
  })

  //泄露名单弹窗展示
  evtCenter.$on('expose-reveal-pop', function(value) {
    sa('send', {
      page_id: 15, 
      page_name: 'page_login',
      activity_name: 'expose_popup_riskaccount_pop',
    })
  })

  // 登录模块register按钮点击
  evtCenter.$on('click-register-btn', function({ checkboxPrivacy}) {
    const params = {}
    if (typeof checkboxPrivacy !== 'undefined') {
      params.privacy_Unchecked = checkboxPrivacy ? 1 : 0
    }
    saSend('register', params )
  })

  // 点击密码显示icon
  evtCenter.$on('click-display-password-btn', function(val) {
    gaSend({ action: 'ClickPasswordDisplay', label: val ? 'On' : 'Off'})
  })

  // 聚焦忘记密码输入框事件
  evtCenter.$on('focus-forget-input', function(val) {
    val && gaSend({ action: 'EditRePSdEmail', value: 0 })
  })

  // 点击忘记密码提示ClickTab
  evtCenter.$on('click-forget-password-tip', function(res) {
    gaSend({ action: 'ClickForgotYourPassword' })
    sa('send', 'pageEnter', { page_id: 504, page_name: 'page_forgot_pw', start_time: new Date().getTime() })
  })

  // 忘记密码邮件发送请求成功
  evtCenter.$on('request-forget-submit-success', function(res) {
    const {  msg } = res
    const code = parseInt(res.code, 10)
    const isSuccess = code == 0
    gaSend({ action: 'Submit-RePSdEmail', value: isSuccess === 0 ? 1 : 0})

    const message = ((code) => {
      if (code === 400505) return 'not_found_email'
      return msg || ''
    })(code)

    saSend('submit_result', { status: isSuccess ? 'success' : 'failure', failure_reason: message },
      false, false,
      { page_id: 504, page_name: 'page_forgot_pw'})

  })

  // 点击忘记密码弹窗的关闭按钮
  evtCenter.$on('click-forget-password-close', function() {
    gaSend({ action: 'Cancel-RePSdEmail'})
    saSend('return', {}, false, false, { page_id: 504, page_name: 'page_forgot_pw'})
  })

  // 点击忘记密码提交按钮
  evtCenter.$on('click-forget-submit-btn', function(res) {
    saSend('submit', {}, false, false, { page_id: 504, page_name: 'page_forgot_pw'})
  })

  // 点击显示隐私政策条例事件
  evtCenter.$on('click-privacy-show', function() {
    gaSend({ action: 'ClickPrivacyPolicy', category: 'GDPR-Register'})
    saSend('privacy_policy')
  })

  // 请求本站3方登录接口成功
  evtCenter.$on('request-third-success', function(data) {
    const { res, type } = data
    const code = parseInt(res.code, 10)
    const isSuccess = code == 0
    const isRegister = res && res.info && res.info && res.info.member && parseInt(res.info.member.isRegister, 10) === 1

    const gaType = isRegister ? `Register_${type}` : `SignIn_${type}`
    isSuccess && gaSend({
        action: gaType,
        value: isSuccess ? 1 : 0,
        beacon: true })

    sensorsSend(isRegister ? '2-8-3' : '2-8-2', { 
      is_success: isSuccess, 
      fail_reason: isSuccess ? '' : `Fail_${res.msg}`, 
      register_method: type 
    })

    isSuccess && saSend('signin_third_party_result', {
        third_party: type,
        type: isRegister ? 'register' : 'login',
    }, false, true)
  })


  // 登录请求错误
  evtCenter.$on('request-login-error', function(from) {
    gaSend({ action: 'SignIn_Email', label: 'Fail_request error', value: 0 })
    sensorsSend('2-8-2', { 
      is_success: false, 
      fail_reason: 'Fail_request error',
      register_method: 'email'
    })
    const actname = from === 'register-page' ? 'popup_mailboxregistered_signin_result' : 'signin_result'
    saSend(actname, { status: 'failure', failure_reason: 'network_error' })
  })
  // 登录请求成功
  evtCenter.$on('request-login-success', function(val) {
    const { res, from, actName, callBySignup } = val
    const code = parseInt(res.code , 10)
    const isSuccess = code == 0
    const isFromRegister = from === 'register-page'

    // 如果是来自注册调用的登录接口，不发送埋点
    if (callBySignup === 'callBySignup') return

    const msg = (function(code) {
      if (code == 400504) return 'unmatched_password_and_email'
      return 'other'
    })(code)

    gaSend({
      action: 'SignIn_Email',
      label: isSuccess ? 'Success' : `Fail_${res.msg}`,
      value: isSuccess ? 1 : 0,
      beacon: true,
    })

    sensorsSend('2-8-2', { 
      is_success: isSuccess, 
      fail_reason: isSuccess ? '' : `Fail_${res.msg}`,
      register_method: 'email'
    })

    if (actName === 'student_register_201903' && lang === 'us') { // 来自活动页
      gaSend({ action: 'Register - 活动页', label: isSuccess ? 'Success' : `Fail-${res.msg}`, value: isSuccess ? 1 : 0 })
    }

    const actname = isFromRegister ? 'popup_mailboxregistered_signin_result' : 'signin_result'

    saSend(actname, {
      status: isSuccess ? 'success' : 'failure',
      failure_reason: msg
    }, false, true)
  })

  // 注册请求错误
  evtCenter.$on('request-register-error', function(from) {
    gaSend({ action: 'Register', label: 'Fail- request error', value: 0 })
    const actname = from === 'login-page' ? 'popup_mailboxsignin_register_result' : 'signin_result'
    saSend(actname, { status: 'failure', failure_reason: 'network_error' })
  })
  // 注册请求成功
  evtCenter.$on('request-register-success', function(val) {
    const { res, from, privacyStatus, checkboxPrivacy, checkboxSubscrib } = val
    const code = parseInt(res.code , 10)
    const isSuccess = code == 0
    const isFromRegister = from === 'register-page'

    const msg = (function(code) {
      if (code == 400517) return 'IP_limit_error'
      if (code == 400503) return 'registered_email'
      return 'other'
    })(code)

    gaSend({
      // 从登陆tab的未注册弹框点击确定注册成功的(Register - Direct)
      action: 'Register_Email',
      label: isSuccess ? 'Success' : 'Fail_' + res.msg,
      value: isSuccess ? 1 : 0,
      beacon: isSuccess
    })
    sensorsSend('2-8-3', { 
      is_success: isSuccess, 
      fail_reason: isSuccess ? '' : 'Fail_' + res.msg,
      register_method: 'email',
    })

    const actname = isFromRegister ? 'register_result' : 'popup_mailboxsignin_register_result'

    const opts = { status: isSuccess ? 'success' : 'failure', failure_reason: msg, subscribe_status: isShowEmailSubscribe() ? (checkboxSubscrib ? 1 : 0) : '-'  }
    if (privacyStatus) { // 如果有展示隐私协议
      opts.privacy_policy = checkboxPrivacy ? 1 : 0
      opts.email_receive = checkboxSubscrib ? 1 : 0
    }
    saSend(actname, opts, false, true)
  })

  // 页面关闭登录页按钮
  evtCenter.$on('click-close-login-page', function(lable) {
    gaSend({ action: window.isLoginPageAsCompnent ? 'ClosePopUps_Sign/Register' : 'Close'})
    sensorsSend('2-8-4')
  })

  // 检测邮箱是否存在
  evtCenter.$on('request-check-member-email-exists', function(data) {
    const res = data.res
    const type = data.from
    // res.info.result === 0 表示邮箱未注册
    if(res && parseInt(res.code, 10) === 0 && res.info && parseInt(res.info.result, 10) === 0){
      if(type === 'login-page') { // 来自登录tab的邮箱检测
        gaSend({ action: 'SignIn_Email', value: 0, label: 'Fail_Mailbox not registered'})
        gaSend({ action: 'PopUps-DirectRegister' })
        saSend('signin_result', { status: 'failure', failure_reason: 'unregistered_email'})
        saSend('popup_mailboxsignin', {}, true)
        sensorsSend('2-8-2', { is_success: false, fail_reason: 'Fail_Mailbox not registered', register_method: 'email' })
      }
      return
    }
    if(type === 'register-page') {
      gaSend({ action: 'PopUps-DirectSignIn' })
      gaSend({ action: 'Register_Email', value: 0, label: 'Fail_EmailHasBeenUsed', beacon: true })
      saSend('register_result', { status: 'failure', failure_reason: 'EmailHasBeenUsed' })
      saSend('popup_mailboxregistered', {}, true)
      sensorsSend('2-8-3', { is_success: false, fail_reason: 'Fail_EmailHasBeenUsed', register_method: 'email' })
    }
  })

  // 取消未注册弹窗
  evtCenter.$on('click-cancel-register-modal', function(data) {
    gaSend({ action: 'ClickCancel-DirectRegister'})
    saSend('popup_mailboxregistered_cancel')
  })

  // 注册弹窗的注册按钮点击事件
  evtCenter.$on('click-pop-register-btn', function() {
    gaSend({ action: 'ClickConfirm-DirectRegister'})
    saSend('popup_mailboxsignin_register')
  })

  // 取消已注册弹窗
  evtCenter.$on('cancel-has-registered-modal', function(data) {
    gaSend({ action: 'ClickCancel-DirectSignIn' })
    saSend('popup_mailboxregistered_cancel')
  })

  // 注册tab点击已登录弹窗的确定按钮
  evtCenter.$on('click-pop-login-btn', function() {
    gaSend({ action: 'ClickConfirm-DirectSignIn'})
  })

  // 展示五次错误密码弹窗
  evtCenter.$on('show-forget-password-modal', function() {
    gaSend({ action: 'PopUps-WrongPassword' })
  })

  // 取消五次错误密码弹窗
  evtCenter.$on('click-cancel-password-modal', function(data) {
    gaSend({ action: 'ClickTryAgain'})
  })

  evtCenter.$on('click-sure-password-modal', function() {
    gaSend({ action: 'ClickRetrievePassword' })
  })

  evtCenter.$on('user-login-input-error', function(data) {
    const { msg, gamsg, from } = data
    const actname = from === 'pop' ? 'popup_mailboxsignin_register_result' : 'signin_result'
    const action = 'SignIn_Email'
    saSend(actname, { status: 'failure', failure_reason: msg })
    gaSend({ action, value: 0, label: `Fail_${gamsg}`, beacon: true})
    sensorsSend('2-8-2', { is_success: false, fail_reason: `Fail_${gamsg}`, register_method: 'email' })
  })

  evtCenter.$on('user-register-input-error', function(data) {
    const { msg, gamsg, privacyStatus, checkboxPrivacy, checkboxSubscrib } = data

    const opts = { status: 'failure', failure_reason: msg }
    if (privacyStatus) { // 如果有展示隐私协议
      opts.privacy_policy = checkboxPrivacy ? 1 : 0
      opts.email_receive = checkboxSubscrib ? 1 : 0
    }
    saSend('register_result', opts)
    gaSend({ action: 'Register_Email', value: 0, label: `Fail_${gamsg}`, beacon: true})
    sensorsSend('2-8-3', { 
      is_success: false, 
      fail_reason: `Fail_${gamsg}`,
      register_method: 'email'
    })
  })

  evtCenter.$on('request-facebook-login', function(data) {
    if (data && !data.authResponse) {
      // gaSend({ action: 'SignIn_Facebook', label: 'Facebook', value: 0 })
      // sensorsSend('2-8-2', { is_success: false, fail_reason: `Fail_network error`, register_method: 'facebook' })
    }
  })

  evtCenter.$on('no-check-privacy-option', function() {
    const actname = 'register_result'
    const opts = { status: 'failure', failure_reason: '', privacy_policy: 0 }
    saSend(actname, opts, false, true)
    gaSend({ action: 'Register', value: 0, beacon: true, label: 'Fail-Please agree to our Privacy Policy'})
  })

  // 获取绑定冲突方式场景值
  function getGaBindSences(info) {
      const { type, source, originType } = info
      if (type === 'failure') {
          return 'FRF'
      }
      let t = type
      if (type === 'success' || type === 'failure') t = originType
      if (t === 'bind') { // 绑定邮箱
          return 'NoMailbox'
      }
      if (source !== 'Normal' && t === 'loginBind') { // 社交媒体账号与普通账号邮箱
          return 'SRO'
      }
      // 三方注册与三方账号冲突
      if (source !== 'Normal' && t === 'third') {
          return 'SRS'
      }
      if (t === 'success') {
          return 'AutoBind'
      }
      return 'ORS'
  }

  function getBiBindSences(info) {
      const { type, source, originType } = info
      
      // 三方注册重复渠道绑定失败
      if (type === 'failure') {
          return 'popup_soc_socmrepeat_result'
      }
      let t = type
      if (type === 'success' || type === 'failure') t = originType
      if (t === 'success') {
          return 'popup_autobind'
      }
      // 三方账号注册没有邮箱，需要绑定邮箱
      if (t === 'bind') {
          return 'popup_soc_bindmailbox'
      }
      // 三方注册与三方账号冲突
      if (source !== 'Normal' && t === 'third') { 
          return 'popup_soc_socmrepeat_selectchannel'
      }
      
      // 三方注册与普通邮箱冲突
      if (source !== 'Normal' && t === 'loginBind') {
          return 'popup_soc_ordmrepeat'
      }

      // 普通邮箱与三方邮箱冲突
      return 'popup_ord_socmrepeat'
  }

  evtCenter.$on('show-bind-email-modal', function(data) {
      const { type, source } = data
      if (type === '') return
      // 如果是直接注册成功或者
      // if (isDirectFailure || isDirectSuccess) return
      const gaSences = getGaBindSences(data)
      let action = `PopupBind - ${gaSences}`
      if (type === 'success') action = `PopupSuceess - ${gaSences}`
      if (gaSences) gaSend({ action })
      
      let bname = getBiBindSences(data)
      
      let param = {}
      if (type === 'success') {
          bname = `${bname}_result`
          param = { status: 'success' }
          if (source !== 'Normal') param.third_party = source
      }
      if (type === 'failure') {
          param = { status: 'failure'}
          if (source !== 'Normal') param.third_party = source
      }
      saSend(bname, param, true, false)
  })

  evtCenter.$on('click-bind-email', function(data) {
      const { info, msg, biMsg } = data
      const gaSences = getGaBindSences(info)
      gaSend({ action: `Submit - ${gaSences}`, label: `Fail - ${msg}`, value: 0 })
      const biSences = getBiBindSences(info)
      saSend(`${biSences}_submit`, { status: 'failure',  failure_reason: biMsg })
  })

  evtCenter.$on('click-third-platform-submit', function(data) {
      const { info, target } = data
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickSignIn`, label: `PopupBind-${gaSences}-${target}`})
      const biSences = getBiBindSences(info)
      saSend(biSences, { third_party: target })
  })

  evtCenter.$on('click-bind-success-confirm', function(data) {
      const { info } = data
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickConfirm`, label: `PopupSuceess-${gaSences}`})
  })

  evtCenter.$on('click-to-bind', function(data) {
      const { info } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickChangeMailbox`, label: `PopupBind-${gaSences}`})
      saSend(`${activityName}_change`)
  })
  evtCenter.$on('click-to-register', function(data) {
      const { info } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickForgotYourPassword`, label: `PopupBind-${gaSences}`})
      saSend(`${activityName}_change`)
  })
  evtCenter.$on('click-to-forget', function(data) {
      const { info } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickForgotYourPassword`, label: `PopupBind-${gaSences}`})
      saSend(`${activityName}_forgotpw`)
  })

  evtCenter.$on('click-login-bind-confirm', function(data) {
      const { info, gmsg, bmsg } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      gaSend({ action: `Confirm-${gaSences}`, label: `Fail - ${gmsg}`, value: 0 })
      saSend(`${activityName}_submit`, { status: 'failure', failure_reason: bmsg, third_party: info.source })
  })

  evtCenter.$on('request-login-bind-success', function(data) {
      const { info } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      if (gaSences === 'SRO') {
          gaSend({ action: `Confirm-${gaSences}`, label: `Success`, value: 1 })
      }
      // click_popup_soc_ordmrepeat_submit
      if (activityName === 'soc_ordmrepeat') {
          saSend(`${activityName}_submit`, { status: 'success'})
      }
  })

  evtCenter.$on('click-bind-failed-confirm', function(data) {
      const { info } = data
      const activityName = getBiBindSences(info)
      const gaSences = getGaBindSences(info)
      gaSend({ action: `ClickSignIn`, label: `PopupBind-${gaSences}`})
      saSend(`${activityName}_login`)
  })

  evtCenter.$on('request-login-third-result', function(data) {
      const { info, res, type = '' } = data
      const { originType, source } = info
      const gaSences = getGaBindSences(info)
      const activityName = getBiBindSences(info)
      const { code, msg } = res
      const isSuccess = code == 0
      if (originType === 'bind') {
          const isRegister = isSuccess && res && res.info && res.info.member && res.info.member.isRegister
          isSuccess && gaSend({ action: isRegister ? `Register_${type}` : `SignIn_${type}`,
              value: isSuccess ? 1 : 0,
              beacon: true 
          })
          sensorsSend( isRegister ? '2-8-3' : '2-8-2', {
            register_method: (type || '').toLowerCase(),
            is_success: isSuccess,
            fail_reason: isSuccess ? '' : `Fail_${msg}`
          })
          isSuccess && saSend('signin_third_party_result', {
              third_party: type.toLowerCase(),
              type: isRegister ? 'register' : 'login',
          }, false, true)
          isTestUserMedium && gaSend({ 
              action: `Submit - ${gaSences}`, 
              label: code == 0 ? 'Success' : `Fail - ${msg}`,
              value: code == 0 ? 1 : 0
          })
          isTestUserMedium && saSend(`${activityName}_submit`, { 
              status: code == 0 ? 'success' : 'failure', 
              failure_reason: msg ,
              third_party: source,
          })
          return
      }
  })

  return {
    loginPageFrom,
    saSend,
    saSendNew,
    formatGaScenes,
    gaToSensors,
    sensorsSend,
  }
}

const exportsVariables = createInitEvents()
export default exportsVariables